<template>
  <div class="accordion">
    <div v-for="(section, index) in sections" :key="index" class="accordion-section"
         :class="{ active: section.active }">
      <div class="header" @click="toggleSection(index)">
        {{ section.title }}
        <span v-html-safe="getSVG('arrow_right_blue')"></span>
      </div>
      <div class="content accordion-main-content" v-html-safe="section.content" />
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "sections"
  ],
  methods: {
    toggleSection(index) {
      this.sections.forEach((section, i) => {
        if(i === index) {
          section.active = !section.active;
        } else {
          section.active = false;
        }
      });
    }
  },
};
</script>
