<template>
  <div class="auth-container">
    <div class="content">
      <div class="auth">
        <div class="register" v-if="showForm">
          <h1>Registrierung</h1>
          <form @submit.prevent="getCredentials" id="registerForm" class="register-inputFields">
            <div class="input_row name">
              <TextInput type="text" name="firstName" placeholder="Vorname" :required="true"/>
              <TextInput type="text" name="lastName" placeholder="Nachname" :required="true"/>
            </div>
            <div class="input_row mail">
              <TextInput type="email" name="emailAddress" placeholder="E-Mail" :required="true" />
              <div class="error_message" v-if="showMailError">{{mailErrorText}}</div>
            </div>
            <div class="input_row phone">
              <TextInput type="phone" name="phoneNumber" placeholder="Telefon" :required="true"/>
            </div>
            <div class="input_row company">
              <select-box name="plan" placeholder="Tarif" :options="planOptions" :required="true"></select-box>
              <TextInput type="text" name="company" placeholder="Firma/Verein" />
            </div>
            <div class="input_row street">
              <TextInput type="text" name="street" placeholder="Straße" :required="true"/>
              <TextInput type="text" name="houseNumber" placeholder="Nr." :required="true"/>
            </div>
            <div class="input_row city">
              <TextInput type="text" name="postCode" placeholder="PLZ" :required="true"/>
              <TextInput type="text" name="city" placeholder="Ort" :required="true"/>
            </div>
            <div class="input_row">
              <TextInput type="password" name="password" placeholder="Passwort" :required="true"/>
              <div class="error_message" v-if="showPasswordError">{{passwordErrorText}}</div>
            </div>
            <div class="input_row">
              <TextInput type="password" name="passwordConfirm" placeholder="Passwort wiederholen" :required="true"/>
            </div>
            <div class="input_row legal">
              <CheckBox name="checkPrivacyPolicy" :required="true">Ich habe die <router-link to="/privacy">Datenschutzerklärung</router-link> gelesen und akzeptiert.</CheckBox>
            </div>
            <input type="hidden" name="role" value="standard">
            <button type="submit">Kostenlos Registrieren</button>
          </form>
          <p><router-link to="/login">Bereits einen Account? Login.</router-link></p>
        </div>
        <div class="custom_message" v-if="showMessage">
          <h2>{{messageTitle}}</h2>
          <div class="description" v-html-safe="messageText"></div>
          <p><router-link to="/login">Zurück zum Login.</router-link></p>
        </div>
      </div>
      <div class="background" />
      <auth-menu></auth-menu>
    </div>
  </div>
</template>

<script>

import TextInput from "@/components/elements/TextInput.vue";
import SelectBox from "@/components/elements/SelectBox.vue";
import CheckBox from "@/components/elements/CheckBox.vue";
import AuthMenu from "@/components/static/AuthMenu.vue";

export default {
  computed: {
    getLoginLink() {
      return this.$router.resolve({ name: 'login' }).href;
    }
  },
  data() {
    return {
      error: false,
      showForm: true,
      showMailError: false,
      showPasswordError: false,
      mailErrorText: '',
      passwordErrorText: '',
      showMessage: false,
      formIsValid: true,
      messageTitle: '',
      messageText: '',
      planOptions: [
        {
          id: 'privat',
          title: 'Privat'
        },
        {
          id: 'gewerbe',
          title: 'Gewerbe'
        },
        {
          id: 'verein',
          title: 'Verein'
        },
      ],
    }
  },
  components: {AuthMenu, TextInput, SelectBox, CheckBox},
  mounted() {
    this.$store.commit("setPageTitle", "Registrieren");
    this.setBreadCrumbs([]);
  },
  methods: {
    async getCredentials() {

      const data = this.getFormData('registerForm');

      const allRequiredInputs = document.querySelectorAll('input[required]');

      this.showMailError = false;
      this.showPasswordError = false;
      this.formIsValid = true;

      allRequiredInputs.forEach(input => {
        if (input.type === 'checkbox' && !input.checked) {
          this.formIsValid = false;
          input.classList.add('error');
        }
        else if ((input.type === 'text' || input.type === 'email' || input.type === 'password') && input.value.trim() === '') {
          input.classList.add('error');
          this.formIsValid = false;
        }
        else if(input.name === 'password' && input.value !== data.passwordConfirm) {
          input.classList.add('error');
          this.showPasswordError = true;
          this.passwordErrorText = 'Die eingegebenen Passwörter stimmen nicht überein.';
          this.formIsValid = false;
        }
        else if(input.name === 'passwordConfirm' && input.value !== data.password) {
          input.classList.add('error');
          this.showPasswordError = true;
          this.formIsValid = false;
        }
        else {
          input.classList.remove('error');
        }
      });

      if(this.formIsValid) {

        document.querySelector('.register button[type=submit]').classList.add('loading');

        const res = await this.apiRequest('PUT', '/account/', data, false);
        if(res.success) {

          this.showForm = false;
          this.showMessage = true;

          this.messageTitle = 'Vielen Dank für Ihre Registrierung';
          this.messageText = '<p>Eine Bestätigungs-E-Mail wurde an Ihre angegebene E-Mail-Adresse gesendet. Bitte prüfen Sie Ihren Posteingang und klicken Sie auf den Bestätigungslink, um Ihr Konto zu aktivieren.</p>\n' +
              '          <p>Falls Sie die E-Mail nicht erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner oder kontaktieren Sie unseren Support für weitere Hilfe.</p>';
        } else {
          this.error = true;

          document.querySelector('.register button[type=submit]').classList.remove('loading');

          if(res.cnt.id === 'emailInUse') {
            this.showMailError = true;
            this.mailErrorText = 'E-Mail Adresse bereits in Verwendung.'
          }
        }
      }
    }
  }
}
</script>