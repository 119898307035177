<template>
    <div class="auth-container">
      <div class="content">
        <div class="auth">
          <div class="login" v-if="showLogin">
            <span class="logo" v-html-safe="getSVG('logo')"></span>
            <h1>Albertmagazin.de</h1>
            <form @submit.prevent="getCredentials" id="loginForm" class="login-inputFields">
              <div class="input_row">
                <TextInput type="email" name="email" placeholder="E-Mail Adresse" />
              </div>
              <div class="input_row">
                <TextInput type="password" name="password" placeholder="Passwort" />
                <div class="error_message" v-if="loginError">{{loginErrorText}}</div>
              </div>
              <button type="submit">Anmelden</button>
            </form>
            <p><router-link to="/createAccount">Noch keinen Account? Registrieren.</router-link></p>
            <p><router-link to="/forgotPassword">Passwort vergessen?</router-link></p>
          </div>
          <div class="custom-message" v-if="showMessage">
            <h2 class="heading">{{ messageTitle }}</h2>
            <div class="description" v-html-safe="messageText"></div>
            <div @click="closeMessage" class="closeMessage">Zurück zum Login.</div>
          </div>
        </div>
        <div class="background"></div>
        <auth-menu></auth-menu>
      </div>
    </div>
</template>

<script>

import TextInput from "@/components/elements/TextInput.vue";
import AuthMenu from "@/components/static/AuthMenu.vue";
import NoticeToast from "@/components/elements/NoticeToast.vue";

export default {
    computed: {
      getLoginLink() {
        return this.$router.resolve({ name: 'login' }).href;
      }
    },
    data() {
        return {
          error: false,
          showLogin: true,
          showMessage: false,
          messageTitle: '',
          messageText: '',
          loginError: false,
          loginErrorText: ''
        }
    },
  components: {AuthMenu, TextInput, NoticeToast},
    mounted() {
        this.$store.commit("setPageTitle", "Login");
        this.setBreadCrumbs([]);
        this.checkMailToken();
    },
    methods: {
      extractParameterFromURL(parameter) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(parameter);
      },
      async getCredentials() {

        document.querySelector('form button[type=submit]').classList.add('loading');
        this.loginError = false;

        const data = this.getFormData('loginForm');
        const res = await this.apiRequest('POST', '/account/login', data, false);

        if(res.success) {
          this.setSession(res.token);
          this.$store.state.user.firstName = res.firstName;
          this.$store.state.user.capabilities = res.capabilities;

          this.$router.push('/');
        } else {
          this.error = true;

          if(res.cnt.id === 'userVerifyTokenExpired') {
            this.showMessage = true;
            this.showLogin = false;
            this.messageTitle = 'Bestätigungslink abgelaufen';
            this.messageText = '<p>Es wurde ein neuer Bestätigungslink an Ihre E-Mail-Adresse gesendet.</p>';
          }
          else if(res.cnt.id === 'mailNotConfirmed') {
            this.showMessage = true;
            this.showLogin = false;
            this.messageTitle = 'E-Mail Bestätigung noch ausstehend';
            this.messageText = '<p>Es wurde eine Bestätigungsmail an Ihre E-Mail-Adresse gesendet.</p><p>Bitte überprüfen Sie Ihren Posteingang.</p>';
          }
          else if(res.cnt.id === 'wrongCredentials') {
            this.loginError = true;
            this.loginErrorText = 'E-Mail Adresse oder Passwort falsch!'
          } else if(res.cnt.error) {
            this.loginError = true;
            this.loginErrorText = res.cnt.error
          }

          document.querySelector('form button[type=submit]').classList.remove('loading');
        }
      },
      async checkMailToken() {
        const confirmToken = this.extractParameterFromURL('confirmToken');
        const mail = this.extractParameterFromURL('mail');

        if (confirmToken && mail) {
          const data = {
            confirmToken: confirmToken,
            emailAddress: mail
          };

          const res = await this.apiRequest('POST', '/account/verifyMail', data, false);

          if (res.success) {
            this.showMessage = true;
            this.showLogin = false;
            this.messageTitle = 'Erfolgreich bestätigt';
            this.messageText = 'Vielen Dank! Sie haben Ihr Konto erfolgreich verifiziert.'
          } else {
            this.error = true;

            if(res.cnt.id === 'userVerifyTokenExpired') {
              this.showMessage = true;
              this.showLogin = false;
              this.messageTitle = 'Konto noch nicht verfiziert';
              this.messageText = '<p>wir bedauern, Ihnen mitteilen zu müssen, dass Ihr Bestätigungslink abgelaufen ist.</p><p>Bestätigungslinks sind aus Sicherheitsgründen zeitlich begrenzt.</p><p>Für einen neuen Bestätigungslink können Sie sich erneut anmelden.</p>';
            }
            else if(res.cnt.id === 'userAlreadyVerified') {
              this.showMessage = true;
              this.showLogin = false;
              this.messageTitle = 'Verifizierung abgeschlossen';
              this.messageText = '<p>Ihr Konto ist bereits verifiziert.</p>';
            }
            else if(res.cnt.id === 'userVerifyTokenInvalid') {
              this.showMessage = true;
              this.showLogin = false;
              this.messageTitle = 'Ungültiger Bestätigungslink';
              this.messageText = 'Der Bestätigungslink ist ungültig. Bitte loggen Sie sich erneut ein, um einen neuen anzufordern.';
            }
          }
        }
      },
      closeMessage() {
        this.showMessage = false;
        this.showLogin = true;
        this.$router.push('/');
      }
    }
}
</script>