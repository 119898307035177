<template>
  <button
      :style="this.customColor !== undefined ? 'background-color: '+customColor+'; border-color: '+customColor+';' : ''"
      class="button button-primary">{{ text }}
  </button>
</template>
<script>
export default {
  props: [
    "text",
    "customColor"
  ],
}
</script>