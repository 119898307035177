import {createStore} from 'vuex'

export default createStore({
    state() {
        return {
            pageTitle: "Dashboard",
            reevaluateLogin: 0,
            confirmationOverlay: {},
            breadCrumbs: [],
            user: {},
            toast: {},
            lightMode: true,
        }
    },
    getters: {},
    mutations: {
        setPageTitle(state, title) {
            state.pageTitle = title;
            document.title = title + " | Albertmagazin";
        },
        setUser(state, user) {
            state.user = user;
        },
        setToast(state, status) {
            status.visible = true;
            state.toast = status;
        },
        toggleLightMode(state) {
            state.lightMode = !state.lightMode;
            if (state.lightMode) {
                document.body.classList.add("light-mode");
            } else {
                document.body.classList.remove("light-mode");
            }
        },
    },
    actions: {},
    modules: {}
})
