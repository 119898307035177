<template>
  <div class="header-bar-wrapper">
    <div class="header-bar">
      <div class="header-bar-left">
        <div class="header-bar-left-greeting">Willkommen, {{ this.$store.state?.user?.firstName }}
          {{ this.$store.state?.user?.lastName }}!
        </div>
        <div class="header-bar-left-date" v-html-safe="getCurrentDate" />
      </div>
      <div class="header-bar-right">
        <div class="header-bar-links">
          <div v-for="route in getUserPages" class="header-bar-link-container">
            <router-link v-if="route.type === 'internal'" :to="route.url">{{ this.titles[route.name] }}</router-link>
            <a v-else :href="route.url" target="_blank">{{ this.titles[route.name] }}</a>
          </div>
          <div class="header-bar-logout" @click="destroySession">
            <span class="name">Abmelden</span>
            <span class="logo" v-html-safe="getSVG('logout_active')"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titles: {
        priceList: 'Preisliste',
        imprint: 'Impressum',
        privacy: 'Datenschutz',
        help: 'Hilfe/FAQ'
      }
    }
  },
  computed: {
    getCurrentDate() {
      let date = new Date();
      const options = {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'};
      return date.toLocaleDateString('de-DE', options);
    },
    getUserPages() {
      return [
        {
          name: 'priceList',
          url: 'https://roedental.de/images/rathaus-service/Amtsblatt-Albert/Anzeigenpreise.pdf',
          type: 'external'
        },
        {
          name: 'imprint',
          url: '/imprint',
          type: 'internal'
        },
        {
          name: 'privacy',
          url: '/privacy',
          type: 'internal'
        },
        {
          name: 'help',
          url: '/help',
          type: 'internal'
        },
      ]
    },
  }
}
</script>
