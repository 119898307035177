// External modules
import {createApp} from 'vue';
import store from './modules/store/index';
import cookies from 'vue-cookies';
import VueSecureHTML from './modules/vue-safe-html';

// App-file
import App from './App.vue';
import mitt from 'mitt'

// Mixins
import functionsMixin from './modules/mixin/functions';
import configMixin from './modules/mixin/config';
import loginMixin from './modules/mixin/login';

// Router
import createRouter from './modules/router/index';

// CSS

import './assets/css/mobileMenu.css';
import './assets/css/editEdition.css';
import './assets/css/articles.css';
import './assets/css/createArticle.css';
import './assets/css/legal.css';
import './assets/css/help.css';
import './assets/css/users.css';
import './assets/css/myaccount.css';
import './assets/css/settings.css';
import './assets/css/advertisements.css';
import './assets/css/editions.css';
import './assets/css/home.css';
import './assets/css/auth.css';
import './assets/css/login.css';
import './assets/css/register.css';
import './assets/css/forgotPassword.css';
import './assets/css/style.css';
import './assets/css/mainMenu.css';
import './assets/css/headerBar.css';


// Create app and configure router, mixins, plugins, etc.
const app = createApp(App).use(store);
const emitter = mitt();

const router = createRouter(app);
app.use(router);

app.config.globalProperties.emitter = emitter

app.use(cookies)
    .use(VueSecureHTML)
    .mixin(configMixin)
    .mixin(functionsMixin)
    .mixin(loginMixin)
    .mount('#app');