<template>
  <div class="legalContainer">
    <div v-if="!loggedIn" class="go_back"><router-link to="/"><span v-html-safe="getSVG('back_black')"></span> Zurück zum Login</router-link></div>
    <h1>Impressum</h1>

    <div class="legal_text">
      Verantwortlich für die Seiten der Stadtverwaltung Rödental:<br>
      <br>
      STADT RÖDENTAL<br>
      <br>
      Bürgerplatz 1<br>
      96472 Rödental<br>
      Tel.: 09563 / 96-0<br>
      Fax: 09563 / 96-49<br>
      Email: info@roedental.de<br>
      <br>
      1. Bürgermeister Herr Marco Steiner<br>
      <br>
      Bei der Realisierung der Internetanwendung wurden vorgegebene Inhalte nach Möglichkeit korrekt und fehlerfrei wiedergegeben. Die Verantwortung für die Richtigkeit der Inhalte liegt allein beim Herausgeber dieser Seiten. Von diesem wird jedoch keine Haftung übernommen bezüglich Schäden, die durch eine missbräuchliche Nutzung entstehen.<br>
      <br>
      <b>Haftung für Inhalte</b><br><br>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br>
      <br>
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.<br>
      <br>
      <b>Urheberrecht</b><br><br>
      Layout und Gestaltung des Angebots insgesamt sowie seiner einzelnen Elemente sind urheberrechtlich geschützt.<br>
      <br>
      Wir haben uns bemüht, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Sounds und Texte zu beachten, selbst erstellte Grafiken, Sounds und Texte zu nutzen oder auf lizenzfreie Grafiken, Sounds und Texte zurückzugreifen. Sollte sich auf den jeweiligen Seiten dennoch eine ungekennzeichnete, oder durch ein fremdes Copyright geschützte Grafik, ein Sound oder Text befinden, so war dies nicht beabsichtigt. Im Falle einer solchen unbeabsichtigten Copyrightverletzung wird die Stadt das entsprechende Objekt nach Benachrichtigung aus seiner Publikation entfernen bzw. mit dem entsprechenden Copyright kenntlich machen. Das Copyright für veröffentlichte, von der Stadt selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Sound und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung nicht gestattet. Dies bezieht sich nicht auf Pressemeldungen, Bild- und Textearchiv. Hier ist eine Verwendung ausdrücklich erwünscht.<br>
      <br>
      <b>Elektronische Kommunikation mit der Stadt Rödental</b><br><br>
      nach Art. 3a Bayerisches Verwaltungsverfahrensgesetz (BayVwVfG)<br>
      <br>
      Die Übermittlung von elektronischen Dokumenten an die Stadt Rödental ist nur unter folgenden Voraussetzungen zulässig: Zugänge im Sinne dieser Zugangseröffnung sind die im Behördenwegweiser publizierten E-Mail-Adressen.<br>
      <br>
      Weitere Formate sind nur mit ausdrücklicher Zustimmung der Empfangsstelle zulässig. In allen zulässigen Formaten dürfen keine automatisierten Abläufe oder Programmierungen (sog. Makros) verwendet werden. E-Mails werden nur bis zu einer Größe von sieben Megabyte (incl. Anhänge = Attachments) angenommen. Dateien in genannten Formaten können durch Komprimierungsprogramme in den Dateigrößen verringert (gepackt) werden. Die Stadt Rödental verwendet eine Software zur Filterung von unerwünschten E-Mails (Spam-Filter). Durch den Spam-Filter können Ihre E-Mails abgewiesen werden, weil diese durch bestimmte Merkmale fälschlich als Spam identifiziert wurden. Die Stadt Rödental bietet elektronische Dienste und Formulare im Internet unter www.roedental.de an. Diese sind bevorzugt zu verwenden.Wurde eine elektronische Kommunikation eröffnet, geht die Stadt Rödental davon aus, dass die gesamte Kommunikation auf diesem Wege stattfinden kann, soweit andere Vorschriften dem nicht entgegenstehen. Soweit eine förmliche Zustellung erforderlich ist, die einen Zustellnachweis erfordert, ist dies auf elektronischem Weg derzeit nicht möglich.<br>
      <br>
      Ansonsten gelten die Regelungen des Art. 3a BayVwVfG.<br>
      <br>
      <b>Datenschutz</b><br><br>
      Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.<br>
      <br>
      Die im Zusammenhang mit den Internet-Direktdiensten anfallenden personenbezogenen Daten werden ausschließlich zum Zweck der Vertragsabwicklung oder zur Wahrung berechtigter eigener Geschäftsinteressen im Hinblick auf die Beratung und Betreuung unserer Kunden oder Interessenten verarbeitet und genutzt. Die geltenden datenschutzrechtlichen Vorschriften werden beachtet. Eine Weiterleitung Ihrer Daten ohne Rechtsgrundlage an Dritte erfolgt nicht.<br>
      <br>
      Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.<br>
      <br>
      Genauere Informationen zum Datenschutz finden Sie <router-link to="/privacy">hier</router-link>.<br>
      <br>
      <b>Cookies</b><br><br>
      Allgemeine Informationen zu Cookies<br>
      <br>
      Diese Internetseite verwendet Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.<br>
      <br>
      Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies ist die Funktionalität dieser Website eingeschränkt.<br>
      <br>
      Verwendung von Cookies auf unserer Seite<br>
      <br>
      Diese Seite verwendet einen Session-Cookie, der nur gesetzt wird wenn Sie sich einloggen oder bereits eingeloggt sind. Dieser wird benötigt, um Sie als registrierten Benutzer zu identifizieren und Ihre Aktionen zu verarbeiten und zu Ihrem Account zuzuordnen. Wenn Sie das Setzen dieses Cookies verweigern, können Sie sich nicht einloggen und keine Anzeigen oder Artikel auf diesem Portal schalten. Wenn Sie sich von dieser Seite ausloggen (z.B. über den 'Logout'-Button), wird dieser Cookie gelöscht, und erst beim nächsten Login wieder gesetzt. Sollten Sie dennoch eine Anzeige oder Artikel schalten wollen, können Sie eine Nachricht an die im Impressum genannte Adresse senden.
    </div>

    <auth-menu v-if="!loggedIn"></auth-menu>
  </div>
</template>

<script>
import PrimaryButton from "@/components/elements/PrimaryButton.vue";
import Accordion from "@/components/elements/Accordion.vue";
import AuthMenu from "@/components/static/AuthMenu.vue";

export default {
  components: {AuthMenu, PrimaryButton, Accordion},
  mounted() {
    this.$store.commit("setPageTitle", "Impressum");
    this.setBreadCrumbs([]);
  },
  computed: {
    loggedIn() {
      if (typeof this.sessionKey === 'string') {
        this.apiRequest("GET", "/account")
            .then(cnt => {
              this.$store.commit("setUser", cnt);
            })
            .catch(err => console.log(err));
        return true;
      }
      return false;
    },
  }
}
</script>