<template>
    <div :class="'notice-area notice-area-'+type">
        <span v-html-safe="icon"/>
        <slot/>
    </div>
</template>
<script>
export default {
    data() {
    },
    props: [
        "type",
    ],
    computed: {
        icon() {
            switch (this.type) {
                case 'success':
                case 'warning':
                case 'error':
                case 'info':
                    return this.getSVG(this.type);
                default:
                    return this.getSVG("info");
            }
        }
    }
}
</script>