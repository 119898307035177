<template>
  <div class="auth_menu">
    <div class="items">
      <div class="item" v-for="(item, index) in menuItems" :key="index">
        <router-link v-if="item.type === 'internal'" :to="item.url">{{ item.name }}</router-link>
        <a v-else :href="item.url" target="_blank">{{ item.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
        {
          name: 'Registrieren',
          url: '/createAccount',
          type: 'internal'
        },
        {
          name: 'Preisliste',
          url: 'https://roedental.de/images/rathaus-service/Amtsblatt-Albert/Anzeigenpreise.pdf',
          type: 'external'
        },
        {
          name: 'Anleitung',
          url: 'https://www.roedental.de/rathaus-service/amtsblatt-albert',
          type: 'external'
        },
        {
          name: 'Impressum',
          url: '/imprint',
          type: 'internal'
        },
        {
          name: 'Datenschutz',
          url: '/privacy',
          type: 'internal'
        },
        {
          name: 'Tutorial',
          url: '/tutorial',
          type: 'internal'
        },
      ]
    };
  }
};
</script>
