<template>
  <div class="auth-container">
    <div class="content">
      <div class="auth">
        <div class="forgotPassword" v-if="showRequestForm">
          <span class="logo"></span>
          <h1>Passwort-Reset</h1>
          <form @submit.prevent="getCredentials" id="forgotPasswordForm" class="forgotPassword-inputFields">
            <div class="input_row">
              <TextInput type="email" name="emailAddress" placeholder="E-Mail Adresse" />
              <div v-if="showErrorMessage" class="error_message">{{ errorMessageText }}</div>
            </div>
            <button type="submit">Reset anfordern</button>
          </form>
          <p>
            <router-link to="/">Zurück zum Login.</router-link>
          </p>
        </div>
        <div class="setNewPassword" v-if="showResetForm">
          <span class="logo"></span>
          <h1>Neues Passwort</h1>
          <form @submit.prevent="setNewPassword" id="setNewPasswordForm" class="setNewPassword-inputFields">
            <div class="input_row">
              <TextInput :required="true" name="password" placeholder="Passwort" type="password" />
              <div v-if="showErrorMessage" class="error_message">{{ errorMessageText }}</div>
            </div>
            <div class="input_row">
              <TextInput type="password" name="passwordConfirm" placeholder="Passwort wiederholen" :required="true" />
              <div v-if="showErrorMessage" class="error_message">{{ errorMessageText }}</div>
            </div>
            <button type="submit">Passwort speichern</button>
          </form>
        </div>
        <div class="custom_message" v-if="showMessage">
          <h2>{{ messageTitle }}</h2>
          <div class="description" v-html-safe="messageText"></div>
          <p>
            <router-link to="/">Zurück zum Login.</router-link>
          </p>
        </div>
      </div>
      <div class="background" />
      <auth-menu></auth-menu>
    </div>
  </div>
</template>

<script>

import TextInput from "@/components/elements/TextInput.vue";
import SelectBox from "@/components/elements/SelectBox.vue";
import CheckBox from "@/components/elements/CheckBox.vue";
import AuthMenu from "@/components/static/AuthMenu.vue";

export default {
  computed: {
    getLoginLink() {
      return this.$router.resolve({name: 'login'}).href;
    }
  },
  data() {
    return {
      error: false,
      showRequestForm: true,
      showResetForm: false,
      showMessage: false,
      messageTitle: '',
      messageText: '',
      showErrorMessage: false,
      errorMessageText: '',
      passwordSetFormIsValid: true,
    }
  },
  components: {AuthMenu, TextInput, SelectBox, CheckBox},
  mounted() {
    this.$store.commit("setPageTitle", "Passwort vergessen");
    this.setBreadCrumbs([]);
    this.checkResetToken();
  },
  methods: {
    extractParameterFromURL(parameter) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(parameter);
    },
    async getCredentials() {
      this.showErrorMessage = false;

      document.querySelector('form button[type=submit]').classList.add('loading');

      const data = this.getFormData('forgotPasswordForm');

      let res = await this.apiRequest('POST', '/account/requestPassword', data, false);
      if(res.success) {

        this.showRequestForm = false;
        this.showMessage = true;
        this.messageTitle = 'Password Reset angefordert'
        this.messageText = '<p>Wir haben Ihre Anfrage zum Zurücksetzen des Passworts erhalten. In Kürze erhalten Sie eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts.</p>' +
            '<p>Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf den Reset-Link, um Ihr neues Passwort festzulegen.</p>';

      } else {
        this.error = true;

        document.querySelector('form button[type=submit]').classList.remove('loading');

        if(res.cnt.id === 'userNotExisting') {
          this.showErrorMessage = true;
          this.errorMessageText = 'E-Mail Adresse nicht im System vorhanden.'
        } else if(res.cnt.id === 'passwordResetAlreadySent') {
          this.showRequestForm = false;
          this.showMessage = true;
          this.messageTitle = 'Password Reset angefordert'
          this.messageText = '<p>Wir haben Ihre Anfrage zum Zurücksetzen des Passworts erhalten. Eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts wurde bereits an Sie gesendet.</p> <p>Bitte überprüfen Sie Ihren Posteingang und klicken Sie auf den Reset-Link, um Ihr neues Passwort festzulegen.</p>';
          this.errorMessageText = 'Ein Link zum Zurücksetzen des Passwortes wurde bereits gesendet'
        }
      }
    },
    async checkResetToken() {
      const resetToken = this.extractParameterFromURL('token');
      const mail = this.extractParameterFromURL('email');

      if(resetToken && mail) {

        const data = {
          passwordResetToken: resetToken,
          emailAddress: mail
        };

        let res = await this.apiRequest('POST', '/account/validateNewPasswordRequest', data, false);

        if(res.success) {
          this.showRequestForm = false;
          this.showResetForm = true;
        } else {
          this.error = true;
          this.showRequestForm = false;

          this.showMessage = true;
          this.messageTitle = 'Passwort-Reset-Link ist abgelaufen'
          this.messageText = '<p>Leider ist der Link zum Zurücksetzen Ihres Passworts abgelaufen.</p> <p>Bitte fordern Sie einen neuen an.</p>';
        }
      }
    },
    async setNewPassword() {
      const formData = this.getFormData('setNewPasswordForm');

      const allRequiredInputs = document.querySelectorAll('input[required]');
      const mail = this.extractParameterFromURL('email');
      const resetToken = this.extractParameterFromURL('token');

      if(!mail || !resetToken) {
        this.passwordSetFormIsValid = false;
      }

      allRequiredInputs.forEach(input => {
        if(input.name === 'password' && input.value !== formData.passwordConfirm) {
          input.classList.add('error');
          this.passwordSetFormIsValid = false;
        } else if(input.name === 'passwordConfirm' && input.value !== formData.password) {
          input.classList.add('error');
          this.passwordSetFormIsValid = false;
        } else {
          input.classList.remove('error');
        }
      });

      if(this.passwordSetFormIsValid) {

        const data = {
          password: formData.password,
          passwordResetToken: resetToken,
          emailAddress: mail
        };

        let res = await this.apiRequest('POST', '/account/resetPassword', data, false);

        if(res.success) {
          this.showRequestForm = false;
          this.showResetForm = false;

          this.showMessage = true;
          this.messageTitle = 'Passwort wurde erfolgreich zurückgesetzt';
          this.messageText = '<p>Sie haben Ihr Passwort erfolgreich geändert.</p> <p>Sie können sich nun wieder anmelden.</p>';
        } else {
          this.error = true;
        }
      }

    }
  }
}
</script>