<template>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <template v-if="loggedIn">
    <ConfirmationOverlay :model="$store.state.confirmationOverlay"/>
    <NoticeToast :model="$store.state.toast"/>
    <MainMenu/>
    <HeaderBar/>
    <mobile-menu/>
    <div class="wrapper">
      <div class="breadcrumb-wrapper" v-if="$store.state.breadCrumbs.length>0">
        <router-link :to="bc.link" class="breadcrumb" v-for="bc in $store.state.breadCrumbs">
          <span v-html-safe="getSVG('arrow_left')"/>
          {{ bc.title }}
        </router-link>
      </div>
      <router-view/>
    </div>
  </template>
  <template v-else-if="!loggedIn && $route.name === 'forgotPassword'">
    <forgot-password></forgot-password>
  </template>
  <template v-else-if="!loggedIn && $route.name === 'createAccount'">
    <register></register>
  </template>
  <template v-else-if="!loggedIn && $route.name === 'imprint'">
    <imprint></imprint>
  </template>
  <template v-else-if="!loggedIn && $route.name === 'privacy'">
    <privacy></privacy>
  </template>
  <template v-else-if="!loggedIn && $route.name === 'tutorial'">
    <tutorial></tutorial>
  </template>
  <template v-else-if="!loggedIn">
    <login></login>
  </template>
</template>

<script>
import Login from "@/components/views/login.vue";
import Register from "@/components/views/register.vue";
import MainMenu from "@/components/static/MainMenu.vue";
import HeaderBar from "@/components/static/HeaderBar.vue";
import MobileMenu from "@/components/static/MobileMenu.vue";
import ConfirmationOverlay from "@/components/elements/ConfirmationOverlay.vue";
import NoticeToast from "@/components/elements/NoticeToast.vue";
import NoticeArea from "@/components/elements/NoticeArea.vue";
import ForgotPassword from "@/components/views/forgotPassword.vue";
import Imprint from "@/components/views/imprint.vue";
import Tutorial from "@/components/views/tutorial_preLogin.vue";
import Privacy from "@/components/views/privacy.vue";

export default {
  components: {
    Privacy,
    Imprint,
    Tutorial,
    ForgotPassword,
    Login,
    Register,
    MainMenu,
    HeaderBar,
    ConfirmationOverlay,
    NoticeToast,
    NoticeArea,
    MobileMenu
  },
  computed: {
    loggedIn() {
      if (typeof this.sessionKey === 'string') {
        this.apiRequest("GET", "/account")
            .then(cnt => {
              this.$store.commit("setUser", cnt);
            })
            .catch(err => console.log(err));
        return true;
      }
      return false;
    },
  }
}
</script>
