<!-- CheckBox.vue -->
<template>
  <div :class="'checkbox-wrapper standard-margin-bottom' + (inline ? ' inline' : '')">
    <input type="checkbox" :id="'checkBox_'+id"
           :name="name"
           :class="this.class"
           :checked="checked"
           @change="validate"
           @focusout="validate"
           :required="isRequired"
    >
    <span :id="'checkbox-check-'+this.id" class="checkbox-check" v-html-safe="getSVG('check')"></span>
    <label v-if="label !== ''" class="checkbox-label" :for="'checkBox_'+id">{{label}}<slot></slot></label>
  </div>
</template>

<script>
import { v4 as uuid4 } from "uuid";
import { nextTick } from "vue";

export default {
  data() {
    return {
      id: null,
      valid: true,
      isRequired: false,
    };
  },
  mounted() {
    this.id = uuid4();
    this.isRequired = this.required;
  },
  props: [
    "name",
    "class",
    "checked",
    "label",
    "inline",
    "doValidation",
    "required",
  ],
  methods: {
    async validate(event) {
      await nextTick();
      if (this.doValidation) this.globalValidate(event);

      const el = event.target;
      const value = el.checked;

      this.$emit("valueChanged", value, event);
    },
  },
};
</script>
