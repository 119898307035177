<template>
  <div class="legalContainer">
    <div v-if="!loggedIn" class="go_back">
      <router-link to="/"><span v-html-safe="getSVG('back_black')"></span> Zurück zum Login</router-link>
    </div>
    <h1>Tutorial zur Registrierung</h1>

    <div class="legal_text">
      <video src="@/assets/videos/register.mp4" class="tutorial-video-preLogin" controls/>
    </div>

    <auth-menu v-if="!loggedIn"></auth-menu>
  </div>
</template>

<script>
import PrimaryButton from "@/components/elements/PrimaryButton.vue";
import Accordion from "@/components/elements/Accordion.vue";
import AuthMenu from "@/components/static/AuthMenu.vue";

export default {
  components: {AuthMenu, PrimaryButton, Accordion},
  mounted() {
    this.$store.commit("setPageTitle", "Tutorial");
    this.setBreadCrumbs([]);
  },
  computed: {
    loggedIn() {
      if(typeof this.sessionKey === 'string') {
        this.apiRequest("GET", "/account")
            .then(cnt => {
              this.$store.commit("setUser", cnt);
            })
            .catch(err => console.log(err));
        return true;
      }
      return false;
    },
  }
}
</script>