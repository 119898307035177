<template>
  <div class="mobile_overlay" :class="{ 'active': mobileMenuActive }"></div>
  <div class="mobile_head">
    <div class="mobile_header_bar">
      <router-link to="/"><div class="main-menu-logo" v-html-safe="getSVG('logo')"/></router-link>
    </div>
    <div class="mobile_toggle" @click="toggleMenu" v-html-safe="getSVG('menu_burger')"></div>
  </div>
  <div class="mobile-menu" :class="{ 'active': mobileMenuActive }">
    <div class="close" @click="toggleMenu" v-html-safe="getSVG('close')"></div>
    <div class="main-menu-links">
      <div class="main-menu-link-container" v-for="route in getUserPages">
        <router-link :to="'/'+(route.key === 'home' ? '' : route.key)" @click="toggleMenu">
          <span class="logo" v-html-safe="getSVG(route.key)"/>
          <span class="logo_active" v-html-safe="getSVG(route.key + '_active')"/>
          <span class="name">{{route.title}}</span>
        </router-link>
      </div>
      <div class="main-menu-link-container">
        <a @click="destroySession">
          <span class="logo" v-html-safe="getSVG('logout')"/>
          <span class="logo_active" v-html-safe="getSVG('logout_active')"/>
          <span class="name">Abmelden</span>
        </a>
      </div>
      <div class="main-menu-link-container no_icon">
        <router-link :to="'/imprint'" @click="toggleMenu">
          <span class="name">Impressum</span>
        </router-link>
      </div>
      <div class="main-menu-link-container no_icon">
        <router-link :to="'/privacy'" @click="toggleMenu">
          <span class="name">Datenschutz</span>
        </router-link>
      </div>
      <div class="main-menu-link-container no_icon">
        <router-link :to="'/help'" @click="toggleMenu">
          <span class="name">Hilfe/FAQ</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      titles: {
        home: 'Home',
        editions: 'Ausgaben',
        advertisements: 'Anzeigen',
        my_advertisements: 'Meine Anzeigen',
        users: 'Nutzer',
        myaccount: 'Mein Konto',
        settings: 'Einstellungen',
        imprint: 'Impressum',
        privacy: 'Datenschutz',
      },
      firstKnowledgeCategory: null,
      mobileMenuActive: false
    };
  },
  async mounted() {
    window.addEventListener('resize', this.checkToggleMenu);

    for (let link of document.getElementsByClassName("main-menu-link-container")) {
      link.addEventListener("mouseleave", (e) => {
        e.target.classList.add("removed-hover");
        setTimeout(() => {
          e.target.classList.remove("removed-hover");
        }, 500);
      });
    }
  },
  computed: {
    getCurrentDate() {
      let date = new Date();
      const options = {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric'};
      return date.toLocaleDateString('de-DE', options);
    },
    getUserPages() {
      const capabilities = this.$store.state?.user?.capabilities ?? [];

      var pages = [];

      if(capabilities.includes("advanced")) {
        pages = [
          {
            key: 'home',
            title: 'Home'
          },
          {
            key: 'editions',
            title: 'Ausgaben'
          },
          {
            key: 'advertisements',
            title: 'Anzeigen'
          },
          {
            key: 'users',
            title: 'Nutzer'
          },
          {
            key: 'myaccount',
            title: 'Mein Konto'
          },
          {
            key: 'settings',
            title: 'Einstellungen'
          },
        ];
      }
      else if(capabilities.includes("editor")) {
        pages = [
          {
            key: 'home',
            title: 'Home'
          },
          {
            key: 'editions',
            title: 'Ausgaben'
          },
          {
            key: 'advertisements',
            title: 'Anzeigen'
          },
          {
            key: 'myaccount',
            title: 'Mein Konto'
          },
          {
            key: 'settings',
            title: 'Einstellungen'
          },
        ];
      }
      else if(capabilities.includes("default")) {
        pages = [
          {
            key: 'home',
            title: 'Home'
          },
          {
            key: 'editions',
            title: 'Ausgaben'
          },
          {
            key: 'advertisements',
            title: 'Meine Anzeigen'
          },
          {
            key: 'myaccount',
            title: 'Mein Konto'
          },
        ];
      }

      return pages;
    },
    getUserImage() {
      if ((this.$store.state?.user?.image ?? null) !== null) {
        return 'background-image: url(' + this.$store.state.user.image + ');';
      }
      if (this.$store.state.lightMode) {
        return 'background-image: url(' + this.getImage('user_light.svg') + ');';
      }
      return 'background-image: url(' + this.getImage('user.svg') + ');';
    },
    getThemeSwitcher() {
      return !this.$store.state.lightMode ? 'sun' : 'moon';
    }
  },
  methods: {
    toggleMenu() {
      this.mobileMenuActive = !this.mobileMenuActive;
    },
    checkToggleMenu() {
      this.mobileMenuActive = !window.innerWidth > 970;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkToggleMenu);
  }
}
</script>
